import { RouteMap } from '@common/constants/RouteMap';
import { useUserContext } from '@common/contexts/UserContext';
import { useRenegAC } from '@common/hooks/useRenegAc';
import { useTracking } from '@common/hooks/useTracking';
import { useGoogleAnalytics } from '@common/hooks/useTracking/ga';
import redirectToACImplicitAccess, {
  redirectToAC,
} from '@common/utils/redirectToACImplicitAccess';
import { RedirectACData, acRedirectEvent } from '@dues/types/sorting-debt';
import { useApiFirebolt } from '@hub/apis/firebolt/useApiFirebolt';
import { base64Encrypt } from '@libs/utils/helpers/base64';
import { addCookie, getCookie } from '@libs/utils/helpers/getSafeCookies';
import { getAnonymousID, getSessionID } from 'redline-client-sdk';

export function useACRedirect() {
  const { proposalStartedEvent } = useTracking();
  const { user } = useUserContext();
  const { sendSoftProposal } = useApiFirebolt();
  const { trackGA } = useGoogleAnalytics();
  const { enableRenegAC } = useRenegAC()

  const slug = 'collections-acordocerto';
  const partner = 'acordocerto';

  async function sendProposalStartedAndRedirectToAC(
    queryParams = '',
    redirectACData?: RedirectACData,
    redirectDebtsData?: acRedirectEvent,
    isDebtCard?: boolean // TEMP: PROXY RENEG AC IN CP
  ) {
    const fireboltId = await requestToFirebolt();
    const params = completeUrlParams(queryParams, fireboltId, redirectACData);
    const redirectUrl =
      (await redirectToACImplicitAccess(user!.document!, params, false)) || '';

    const decodedRedirectURL = encodeURI(redirectUrl);
    await sendProposalStarted(
      decodedRedirectURL,
      fireboltId,
      redirectDebtsData
    );

    // TEMP: PROXY RENEG AC IN CP
    if(enableRenegAC && isDebtCard) {
      const encodedRedirectPayload = base64Encrypt(JSON.stringify(redirectACData))
      window.location.href = `${RouteMap.MyDebtsAC}?redirect_to=${encodedRedirectPayload}`
      addCookie({ key: 'redirect_to', value: encodedRedirectPayload });
      localStorage.removeItem('SORTING_DEBTS_RES');
    } else {
      redirectToAC(decodedRedirectURL);
    }
    // END TEMP: PROXY RENEG AC IN CP
  }

  function completeUrlParams(
    queryParams: string,
    fireboltId: string,
    redirectACData?: RedirectACData
  ) {
    return (
      queryParams +
      `&session_id=${getSessionID()}&firebolt_id=${fireboltId}` +
      handleRedirectACData(redirectACData)
    );
  }

  async function requestToFirebolt() {
    const item = {
      cpf: user?.document,
      full_name: user?.full_name,
      email: user?.email,
      main_phone: user?.phone,
      date_of_birth: user?.birthdate,
      choosen_card: slug,
      issuer: partner,
    };
    const metadata = {
      start_source_url:
        getCookie('start_source_url') ||
        sessionStorage.getItem('start_source_url'),
      user_agent: window.navigator.userAgent,
      anonymousId: getAnonymousID(),
      sessionId: getSessionID(),
    };
    const proposalResponse = await sendSoftProposal.send(partner, {
      item,
      metadata,
    });

    return proposalResponse.firebolt_id;
  }

  async function sendProposalStarted(
    redirectUrl: string,
    fireboltId: string,
    redirectDebtsData?: acRedirectEvent
  ) {
    const payload = {
      initialSlug: slug,
      finalSlug: slug,
      category: 'collections',
      redirectUrl,
      fireboltId,
      redirectDebtsData,
    };
    await proposalStartedEvent(payload);
    trackGA({ eventName: 'proposalStarted_acordocerto', payload });
  }

  function handleRedirectACData(redirectACData?: RedirectACData) {
    if (!redirectACData) return '';

    const dataInString = JSON.stringify(redirectACData);
    const dataEncrypt = base64Encrypt(dataInString);

    return `&redirect_to=${dataEncrypt}`;
  }

  return {
    sendProposalStartedAndRedirectToAC,
  };
}
