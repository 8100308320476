import { useCallback, useEffect } from 'react';

import { useSH } from '@libs/creditkit';
import { delay } from '@libs/utils/helpers/delay';

import { getToken } from '@common/utils/handleToken';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import { handleErrorLog } from '@common/utils/handleErrorLogs';

type UseSortingHat = {
  immediate?: boolean;
  decideDelayInSeconds?: number;
};
export const useSortingHat = ({
  immediate,
  decideDelayInSeconds,
}: UseSortingHat = {}) => {
  const {
    trackDone,
    callSHTrack,
    callSHDecision,
    decisionsList: shDecisionList,
    resetContext: resetSHContext,
    SHError,
    SHLoading,
  } = useSH();

  const { user, getUser } = useUserContext();
  const {
    shDecisionEnrichedEvent,
    shDecisionOutcomeReceivedEvent,
    shDecisionRequestedEvent,
  } = useTracking();

  useEffect(() => {
    if (!user) getUser();
  }, []);

  useEffect(() => {
    if (immediate) {
      trackDone ? shDecision() : shTrack();
    }
  }, [trackDone, immediate, user]);

  const shTrack = useCallback(() => {
    try {
      if (user) {
        callSHTrack({ user, decisionEnrichedEvent: shDecisionEnrichedEvent });
      } else {
        console.warn('[SH_TRACK] User not found');
      }
    } catch (error) {
      handleErrorLog(error);
    }
  }, [user]);

  const shDecision = useCallback(async () => {
    try {
      if (!trackDone) {
        return console.warn('[SH_DECIDE] Track not done yet');
      }

      if (decideDelayInSeconds) {
        const milliseconds = decideDelayInSeconds * 1000;
        if (milliseconds > 0) {
          await delay(milliseconds);
        }
      }

      const token = await getToken();
      callSHDecision({
        user,
        decisionRequestEvent: shDecisionRequestedEvent,
        decisionOutcomeReceivedEvent: shDecisionOutcomeReceivedEvent,
        token: token?.access_token,
      });
    } catch (error) {
      handleErrorLog(error);
    }
  }, [user, trackDone, decideDelayInSeconds]);

  return {
    shTrack,
    shDecision,
    shDecisionList,
    SHLoading,
    resetSHContext,
    SHError,
  };
};
