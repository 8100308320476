import {
  IconCoin,
  IconGiveMoney,
  IconVelocimeter,
  IconId,
  IconUser,
  IconHelpCircle,
} from '@consumidor-positivo/aurora';
import isDesktop from '@libs/utils/helpers/isDesktop';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

export const usePrivatePagesStates = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dataSourceNavbar = [
    {
      name: 'Início',
      onClick: () => navigate(RouteMap.Dashboard),
      active: location.pathname === RouteMap.Dashboard,
    },
    {
      name: 'Meu Score',
      dropdown: [
        {
          name: 'Aumentar meu Score',
          onClick: () => navigate(RouteMap.Objectives),
          active: location.pathname === RouteMap.Objectives,
        },
        {
          name: 'Histórico de Score',
          onClick: () => navigate(RouteMap.ObjectivesHistoric),
          active: location.pathname === RouteMap.ObjectivesHistoric,
        },
        {
          name: 'Meu progresso',
          onClick: () => navigate(RouteMap.ObjectivesProgress),
          active: location.pathname === RouteMap.ObjectivesProgress,
        },
      ],
    },
    {
      name: 'Minhas Ofertas',
      onClick: () => navigate(RouteMap.Offer),
      active: location.pathname === RouteMap.Offer,
    },
    {
      name: 'Meu CPF',
      dropdown: [
        {
          name: 'Alerta CPF',
          onClick: () => navigate(RouteMap.MonitoringCenter),
          active: location.pathname === RouteMap.MonitoringCenter,
        },
        {
          name: 'Consultas em meu CPF',
          onClick: () => navigate(RouteMap.CpfLookout),
          active: location.pathname === RouteMap.CpfLookout,
        },
        {
          name: 'Contas e pagamentos',
          onClick: () => navigate(RouteMap.BillsAndPayments),
          active: location.pathname === RouteMap.BillsAndPayments,
        },
      ],
    },
    {
      name: 'Central de Ajuda',
      onClick: () => window.open('https://ajuda.acordocerto.com.br/'),
      active: false,
    },
  ];

  const dataSourceNavbarVertical = [
    {
      name: 'Dívidas',
      Icon: <IconCoin />,
      dropdown: [
        {
          name: 'Minhas dívidas',
          onClick: () => navigate(RouteMap.MyDebts),
          active: location.pathname === RouteMap.MyDebts,
        },
        {
          name: 'Meu acordos',
          onClick: () => navigate(RouteMap.MyAgreementsAC),
          active: location.pathname === RouteMap.MyAgreementsAC,
        },
        {
          name: 'Dívidas negativadas',
          onClick: () => navigate(RouteMap.NegativeDebts),
          active: location.pathname === RouteMap.NegativeDebts,
        },
      ],
    },
    {
      name: 'Crédito',
      Icon: <IconGiveMoney />,
      dropdown: [
        {
          name: 'Ofertas de crédito',
          onClick: () => navigate(RouteMap.Offer),
          active: location.pathname === RouteMap.Offer,
        },
        {
          name: 'Match Positivo',
          onClick: () => navigate(RouteMap.MatchMaker),
          active: location.pathname === RouteMap.MatchMaker,
        },
      ],
    },
    {
      name: 'Score',
      Icon: <IconVelocimeter />,
      dropdown: [
        {
          name: 'Aumentar meu Score',
          onClick: () => navigate(RouteMap.Objectives),
          active: location.pathname === RouteMap.Objectives,
        },
        {
          name: 'Histórico de Score',
          onClick: () => navigate(RouteMap.ObjectivesHistoric),
          active: location.pathname === RouteMap.ObjectivesHistoric,
        },
        {
          name: 'Meu progresso',
          onClick: () => navigate(RouteMap.ObjectivesProgress),
          active: location.pathname === RouteMap.ObjectivesProgress,
        },
      ],
    },
    {
      name: 'Meu CPF',
      Icon: <IconId />,
      dropdown: [
        {
          name: 'Alerta CPF',
          onClick: () => navigate(RouteMap.MonitoringCenter),
          active: location.pathname === RouteMap.MonitoringCenter,
        },
        {
          name: 'Consultas em meu CPF',
          onClick: () => navigate(RouteMap.CpfLookout),
          active: location.pathname === RouteMap.CpfLookout,
        },
        {
          name: 'Contas e pagamentos',
          onClick: () => navigate(RouteMap.BillsAndPayments),
          active: location.pathname === RouteMap.BillsAndPayments,
        },
      ],
    },
    {
      name: 'Minha conta',
      Icon: <IconUser />,
      onClick: () => navigate(RouteMap.UserProfile),
      active: location.pathname === RouteMap.UserProfile,
    },
    {
      name: 'Central de Ajuda',
      Icon: <IconHelpCircle />,
      onClick: () => window.open('https://ajuda.acordocerto.com.br/'),
      active: false,
    },
  ].filter((item) => (isDesktop() ? item.name.includes('Minha conta') : item));

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
  };
};
