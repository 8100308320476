import { MatchMakerDecision } from '@hub/types/matchmaker';
import './styles.scss';
import { Button, Text } from '@consumidor-positivo/aurora';
import iconTrash from '@hub/assets/trash.svg';
import { RedirectModal } from '@hub/components/RedirectModal';
import { createPortal } from 'react-dom';
import { useMMRedirect } from '@hub/hooks/useMMRedirect';
import { useTracking } from '@common/hooks/useTracking';
import { CreditDescription } from '@hub/pages/MatchMaker/SuccessFormFlow/components/CreditCard/components/CreditDescription';

interface FavoriteOfferProps {
  offer: MatchMakerDecision;
  handleRemove: (offer: MatchMakerDecision) => void;
  position: number;
}
export const FavoriteOffer = ({
  offer,
  position,
  handleRemove,
}: FavoriteOfferProps) => {
  const { elementClickedEvent } = useTracking();
  const { handleMatchMakerRedirect, loadingRedirect, redirectModal } =
    useMMRedirect();

  const handleClick = async () => {
    elementClickedEvent({
      elementType: 'button',
      name: 'favoritar',
      location: window.location.pathname,
      text: `pedir cartão - ${offer.slug}`,
    });
    await handleMatchMakerRedirect(offer, position);
  };

  return (
    <div className="favorite-offer">
      <div className="favorite-offer__header">
        <img
          className="favorite-offer__header__img-credit"
          src={offer?.imageUrl}
          alt={offer?.productName}
        />
        <img
          className="favorite-offer__header__img-trash"
          src={iconTrash}
          alt="Uma lixeira azul"
          onClick={() => handleRemove(offer)}
        />
        <Text as="h4" variant="heading-micro" weight="bold">
          {offer?.productName}
        </Text>
      </div>
      <CreditDescription offer={offer} position={position} />
      <div className="favorite-offer__buttons">
        <Button expand="x" onClick={handleClick} loading={loadingRedirect}>
          Pedir cartão
        </Button>
      </div>
      {createPortal(
        <RedirectModal
          isOpen={redirectModal.isOpen}
          partnerName={redirectModal.name}
          offerImage={redirectModal.image}
        />,
        document.body
      )}
    </div>
  );
};
