import { axiosRequestAdapter } from '@alova/adapter-axios';
import { createAlova } from 'alova';
import ReactHook from 'alova/react';

import { alovaStorageAdapter } from '@common/apis/alovaStorageAdapter';
import { handleBeforeRequest } from '@common/utils/handleBeforeRequest';
import { handleErrorLog } from '@common/utils/handleErrorLogs';
import { handleSignOut } from '@common/utils/handleToken';

export const alovaInstanceAuth = createAlova({
  statesHook: ReactHook,
  requestAdapter: axiosRequestAdapter(),
  baseURL: import.meta.env.VITE_AUTH,
  storageAdapter: alovaStorageAdapter,
  localCache: null,
  responded: {
    onSuccess: (response: any) => response?.data,
    onError: async (error) => {
      handleErrorLog(error, {
        notLogSlug: [
          'customer_not_found',
          'invalid_password',
          'blocked_password',
          'validation',
          'invalid_token',
        ],
      });

      if (error?.response?.status === 401) {
        await handleSignOut();
      }
    },
  },
  async beforeRequest(method) {
    await handleBeforeRequest(method);
  },
});

export const alovaInstanceAuthAC = createAlova({
  statesHook: ReactHook,
  requestAdapter: axiosRequestAdapter(),
  baseURL: import.meta.env.VITE_AC_MARKETPLACE_API,
  storageAdapter: alovaStorageAdapter,
  localCache: null,
  responded: {
    onSuccess: (response: any) => response?.data,
    onError: async (error) => {
      handleErrorLog(error, {
        notLogSlug: [
          'customer_not_found',
          'invalid_password',
          'blocked_password',
          'validation',
          'invalid_token',
        ],
      });

      if (error?.response?.status === 401) {
        await handleSignOut();
      }
    },
  },
  async beforeRequest(method) {
    await handleBeforeRequest(method);
  },
});