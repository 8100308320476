import { lazy, useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

const CpfLookout = lazy(() => import('../pages/CpfLookout'));
const NegativeDebts = lazy(() => import('../pages/NegativeDebts'));
const BillsAndPayments = lazy(() => import('../pages/BillsAndPayments'));

const ProductList = lazy(() => import('../pages/ProductList'));
const ProductDetail = lazy(() => import('../pages/ProductDetail'));
const InstallmentDetail = lazy(() => import('../pages/InstallmentDetail'));

export function useBillsRouter() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.isNativeApp) {
      const currentPath = window.location.pathname;

      if (
        currentPath !== RouteMap.BillsAndPayments &&
        currentPath.includes(RouteMap.BillsAndPayments)
      ) {
        navigate(RouteMap.BillsAndPayments);
      }
    }
  }, []);

  return (
    <>
      <Route path={RouteMap.CpfLookout} element={<CpfLookout />} />
      <Route path={RouteMap.NegativeDebts} element={<NegativeDebts />} />
      <Route path={RouteMap.BillsAndPayments} element={<BillsAndPayments />} />
      <Route
        path={`${RouteMap.BillsAndPayments}/:categorySlug`}
        element={<ProductList />}
      />
      <Route
        path={`${RouteMap.BillsAndPayments}/:categorySlug/:productId`}
        element={<ProductDetail />}
      />
      <Route
        path={`${RouteMap.BillsAndPayments}/:categorySlug/:productId/:installmentId`}
        element={<InstallmentDetail />}
      />
    </>
  );
}
