import { lazy } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

import useACPSignUpSignInExperiment from '@common/abtest/experiments/useACPSignUpSignInExperiment';

//Public Routes
const Validation = lazy(() => import('@admission/pages/Validation'));
const SignIn = lazy(() => import('@admission/pages/SignIn'));
const SignUp = lazy(() => import('@admission/pages/SignUp'));
const ForgotPassword = lazy(() => import('@admission/pages/ForgotPassword'));
const RecoverPassword = lazy(() => import('@admission/pages/RecoverPassword'));

const Pin = lazy(() => import('@admission/pages/Pin'));
const Otp = lazy(() => import('@admission/pages/Otp'));

//#region ACP
const ACPSignUp = lazy(() => import('@admission/pages/ACP/SignUp'));
const ACPSignIn = lazy(() => import('@admission/pages/ACP/SignIn'));
const ACPInvalidSignUpKBA = lazy(() => import('@libs/acp/pages/Error/InvalidSignUpKBA'));
const ACPBlockedSignUpKBA = lazy(() => import('@libs/acp/pages/Error/BlockedSignUpKBA'));
//#endregion ACP


//Private Routes
const ChangePassword = lazy(
  () => import('../../admission/pages/ChangePassword')
);
const UserProfile = lazy(() => import('../../admission/pages/UserProfile'));
const ChangeEmail = lazy(() => import('../../admission/pages/ChangeEmail'));

export function useAdmissionRouter() {
	const location = useLocation();
	const { resultIsWithACPSignUpSignIn } = useACPSignUpSignInExperiment();

  const admissionPublicPages = (
    <>
      {resultIsWithACPSignUpSignIn ? <Route
        path={RouteMap.Root}
				index
        element={
          <Navigate to={`${RouteMap.SignIn}${location?.search}`} />
        }
      /> : <Route index element={<Validation />} />
			}
      <Route path={RouteMap.SignIn} element={resultIsWithACPSignUpSignIn ? <ACPSignIn /> : <SignIn />} />
      <Route path={RouteMap.SignUp} element={resultIsWithACPSignUpSignIn ? <ACPSignUp /> : <SignUp />} />
      <Route path={RouteMap.signupACPErrorKBA} element={<ACPInvalidSignUpKBA />} />
      <Route path={RouteMap.signupACPBlockedKBA} element={<ACPBlockedSignUpKBA />} />
	
      <Route path={RouteMap.ForgotPassword} element={<ForgotPassword />} />
      <Route path={RouteMap.RecoverPassword} element={<RecoverPassword />} />
      <Route path={RouteMap.Pin} element={<Pin />} />
      <Route path={RouteMap.Otp} element={<Otp />} />
    </>
  );

  const admissionPrivatePages = (
    <>
      <Route path={RouteMap.ChangePassword} element={<ChangePassword />} />
      <Route path={RouteMap.UserProfile} element={<UserProfile />} />
      <Route path={RouteMap.ChangeEmail} element={<ChangeEmail />} />
    </>
  );

  return {
    admissionPublicPages,
    admissionPrivatePages,
  };
}
