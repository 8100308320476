import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useACPSignUpSignInExperiment = () => {
  const testCookieName = 'dx-cp-acp-signup-signin';

  const VARIANTS = {
    A: 'control',
    B: 'with-cp-acp-signup-signin'
  };

  const currentResult = getCookie(testCookieName, false);

  return {
    resultIsWithACPSignUpSignIn: !!currentResult?.includes(VARIANTS.B),
  };
};

export default useACPSignUpSignInExperiment;