import { useLocation } from "react-router-dom";

export function useDenakop() {
  let location = useLocation();

  function sendPageViewDenakopGA() {
    if (window?.denakop && window?.denakop.displaySingleAd && window?.gtag) {
      window.gtag('event', 'page-view-denakop', {
        page: location.pathname,
      });
    } else {
      console.log('[denakop] - denakop could not be found');
    }
  }

  return {
    sendPageViewDenakopGA
  }
}
