/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { getSessionID, getSessionIDAsync } from 'redline-client-sdk';

import { useRedline } from '@libs/redline';

import {
  AppInfoTrackedPayload,
  DefaultFormContext,
  DenakopAdRenderedPayload,
  DispatchedEvent,
  ElementEventPayload,
  FieldEventPayload,
  FormContinuedStep,
  FormErroredProps,
  FormName,
  ModalViewedEventPayload,
  ProductViewedEventPayload,
  RedirectRequestEventPayload,
  SignInErrorProps,
  SignUpStep,
  SignupErrorProps,
  UserAuthenticatedProps,
  proposalStartedProps,
  userAttributeUpdatedProps,
} from './types';
import { formContextMap, shContext } from './data';
import { useGoogleAnalytics } from './ga';
import constantsRedline from '@common/constants/redline';
import { getCookie, addCookie } from '@libs/utils/helpers/getSafeCookies';
import { getFromStorage } from '@common/utils/handleStorage';
import { StorageMap } from '@common/constants/StorageMap';
import { getRDG } from '@common/utils/getRDGFormatted';
import { useUserContext } from '@common/contexts/UserContext';
import {
  Debt,
  DebtResume,
  PartnerSystems,
  PaymentOptions,
  SortingDebtsCache,
} from '@dues/types/sorting-debt';

export const useTracking = (formName?: FormName) => {
  const { user } = useUserContext();
  const { trackGA } = useGoogleAnalytics();
  const { track: trackRedline, getCorrelationId } = useRedline();
  const [formStartEventDispatch, setFormStartEventDispatch] = useState(false);
  const [inputtedEvent, setInputtedEvent] = useState<DispatchedEvent>({});
  const defaultFormContext: DefaultFormContext | Record<string, any> = formName
    ? formContextMap[formName]
    : {};

  function getRedlineCorrelationId() {
    return getCorrelationId(constantsRedline.RL_TRACK_CID);
  }

  function fieldEvent({
    fieldName,
    isInputtedEvent = true,
    ignoreAlreadyDispatch = false,
  }: FieldEventPayload) {
    const payload = {
      ...defaultFormContext,
      userInputField: {
        fieldName,
      },
    };

    if (!ignoreAlreadyDispatch && inputtedEvent[fieldName]) return;

    const eventName = isInputtedEvent
      ? 'default-fieldInputted'
      : 'default-fieldSelected';

    if (isInputtedEvent) trackRedline.userTracking.fieldInputted(payload);
    else trackRedline.userTracking.fieldSelected(payload);

    trackGA({ eventName, payload });
    setInputtedEvent((prevState: DispatchedEvent) => ({
      ...prevState,
      [fieldName]: true,
    }));
  }

  function pageViewEvent() {
    trackRedline.auto.pageViewed();
    trackGA({ eventName: 'default-pageViewed' });
  }

  function formStartedEvent() {
    if (formStartEventDispatch) return;
    setFormStartEventDispatch(true);

    const eventName = 'default-formStarted';

    trackRedline.userTracking.formStarted(defaultFormContext);
    trackGA({ eventName, payload: defaultFormContext });
  }

  function formContinuedEvent(currentStep: FormContinuedStep | SignUpStep) {
    const getStepName = (step: FormContinuedStep | SignUpStep) => {
      const names = {
        full_name: 'name',
        birthdate: 'birth',
        token: 'validation',
      };
      return names[step.slug as keyof typeof names];
    };
    const eventName = 'default-formContinued';

    const payload = defaultFormContext;
    const slug = getStepName(currentStep) || currentStep.slug;
    const formId = payload.formContext.formId || formName;
    payload.stepContext = {
      stepId: `${formId}-${slug}`,
      stepName: slug,
      stepNumber: currentStep.step,
    };
    trackRedline.userTracking.formContinued(payload);
    trackGA({ eventName, payload: payload });
  }

  function formSubmittedEvent(formPayload: any) {
    const field = Object.entries(formPayload).map(([key, value]) => {
      return { fieldName: key };
    });

    const eventName = 'default-formSubmitted';
    const payload = {
      ...defaultFormContext,
      field,
    };
    trackRedline.userTracking.formSubmitted(payload);
    trackGA({ eventName, payload });
  }

  function formOutcomeReceived() {
    const eventName = 'default-formOutcomeReceived';
    const payload = {
      ...defaultFormContext,
    };

    trackRedline.userTracking.formOutcomeReceived(payload);
    trackGA({ eventName, payload });
  }

  function elementClickedEvent(eventPayload: ElementEventPayload) {
    const eventName = 'default-elementClicked';
    const payload = {
      webElement: {
        ...eventPayload,
      },
    };

    trackRedline.userTracking.elementClicked(payload);
    trackGA({ eventName, payload });
  }

  function elementViewedEvent(eventPayload?: ElementEventPayload) {
    const eventName = 'default-elementViewed';
    const payload = { webElement: { ...eventPayload } };

    trackRedline.userTracking.elementViewed(payload);
    trackGA({ eventName, payload });
  }

  function modalViewedEvent({ modalData, location }: ModalViewedEventPayload) {
    const eventName = 'default-modalViewed';
    const payload = {
      suggestionContext: modalData,
      sortingHatVersion: 'v3',
      location,
    };

    trackRedline.sortingHat.modalViewed(payload);
    trackGA({ eventName, payload });
  }

  function productViewedEvent(
    productViewedEventPayload: ProductViewedEventPayload
  ) {
    const eventName = 'default-productViewed';
    const payload = {
      listId: `cp-logado-ofertas-${productViewedEventPayload?.location}`,
      product: { ...productViewedEventPayload },
      experience: 'logged',
      viewCorrelationId: getRedlineCorrelationId(),
      rdg: getRDG(user?.document || ''),
    };

    trackRedline.ecommerce.productViewed(payload);
    trackGA({ eventName, payload });
  }

  function productClickedEvent(productClickedEventPayload: any) {
    const eventName = 'default-productClicked';
    const { origin } = window.location;

    const payload = {
      actionOutcome: productClickedEventPayload?.url?.includes(origin)
        ? 'internal-link'
        : 'external-link',
      correlationId: getRedlineCorrelationId(),
      listId: `cp-logado-ofertas-${productClickedEventPayload?.product?.location}`,
      experience: 'logged',
      outboundUrl: productClickedEventPayload?.url,
      product: productClickedEventPayload?.product,
      viewCorrelationId: getRedlineCorrelationId(),
      rdg: getRDG(user?.document || ''),
    };

    trackRedline.ecommerce.productClicked(payload);
    trackGA({ eventName, payload });
  }

  function redirectRequestedEvent({
    fireboltId,
    offerSlug,
    originPath,
    redirectURL,
  }: RedirectRequestEventPayload) {
    const payload = {
      originId: originPath,
      redirectRequestedId: offerSlug,
      redirectRequestedUrl: redirectURL,
      fireboltId: fireboltId,
      rdg: getRDG(user?.document || ''),
    };

    trackRedline.experience.redirectRequested(payload);
  }

  function shDecisionEnrichedEvent() {
    const eventName = 'default-shDecisionEnriched';
    const payload = { partner: shContext.partner };

    trackRedline.sortingHat.decisionEnriched(payload);
    trackGA({ eventName, payload });
  }

  function shDecisionRequestedEvent() {
    const eventName = 'default-shDecisionRequested';
    const payload = shContext;

    trackRedline.sortingHat.decisionRequested(payload);
    trackGA({ eventName, payload });
  }

  function shDecisionOutcomeReceivedEvent(decisionProducts: any[]) {
    const eventName = 'default-shDecisionOutcomeReceived';
    const payload = { ...shContext, decisionProducts };

    trackRedline.sortingHat.decisionOutcomeReceived(payload);
    trackGA({ eventName, payload });
  }

  function checkRegisterStatusEvent() {
    const eventName = 'checkRegisterStatus';
    const payload = {
      correlationId: getRedlineCorrelationId(),
    };
    trackRedline.userAuthentication.checkRegisterStatus(payload);
    trackGA({ eventName, payload });
  }

  function signUpStartedEvent() {
    const eventName = 'signUpStarted';
    const payload = {
      correlationId: getRedlineCorrelationId(),
    };
    trackRedline.userAuthentication.signUpStarted(payload);
    trackGA({ eventName, payload });
  }

  function signUpCompletedEvent() {
    const eventName = 'signUpCompleted';
    const payload = {
      correlationId: getRedlineCorrelationId(),
    };
    trackRedline.userAuthentication.signUpCompleted(payload);
    trackGA({ eventName, payload });
  }

  function signUpErroredEvent(errorResponse: SignupErrorProps) {
    const {
      error_slug,
      message: error_message,
      error_code,
      fields,
    } = errorResponse;

    const errorMessage =
      fields && fields.length > 0
        ? `${fields[0].field_name} - ${error_message}`
        : error_message;

    const eventName = 'signUpErrored';
    const payload = {
      correlationId: getRedlineCorrelationId(),
      errorDetails: error_code || 'Unknown details',
      errorMessage: errorMessage || 'Unknown message',
      errorType: error_slug || 'Unknown type',
    };
    trackRedline.userAuthentication.signUpErrored(payload);
    trackGA({ eventName, payload });
  }
  function signInErroredEvent(errorResponse: SignInErrorProps) {
    const { error_slug, message: error_message, error_code } = errorResponse;

    const eventName = 'signInErrored';
    const payload = {
      correlationId: getRedlineCorrelationId(),
      errorDetails: error_code || 'Unknown details',
      errorMessage: error_message || 'Unknown message',
      errorType: error_slug || 'Unknown type',
    };
    trackRedline.userAuthentication.signInErrored(payload);
    trackGA({ eventName, payload });
  }

  function formErroredEvent({ errorResponse, formStep }: FormErroredProps) {
    const { error_slug, message: error_message, error_code } = errorResponse;
    const { stepName, stepPosition } = formStep;

    const eventName = 'formErrored';

    let payload = {
      correlationId: getRedlineCorrelationId(),
      errorDetails: error_code || 'Unknown details',
      errorMessage: error_message || 'Unknown message',
      errorType: error_slug || 'Unknown type',
      formType: formName,
      ...defaultFormContext,
      stepContext: {
        stepId: `consumidor-positivo-${formName}-${stepName}`,
        stepName: stepName,
        stepNumber: stepPosition,
      },
    };

    if (formName === 'app-review') {
      payload = {
        ...payload,
        formType: 'app-review',
        formContext: {
          ...defaultFormContext.formContext,
        },
        stepContext: {
          stepId: 'app-review',
          stepName,
          stepNumber: stepPosition,
        },
      };
    }

    trackRedline.userTracking.formErrored(payload);
    trackGA({ eventName, payload });
  }

  function signInStartedEvent() {
    const eventName = 'signInStarted';
    const payload = {
      correlationId: getRedlineCorrelationId(),
    };
    trackRedline.userAuthentication.signInStarted(payload);
    trackGA({ eventName, payload });
  }

  function signInCompletedEvent() {
    const eventName = 'signInCompleted';
    const payload = {
      correlationId: getRedlineCorrelationId(),
    };
    trackRedline.userAuthentication.signInCompleted(payload);
    trackGA({ eventName, payload });
  }

  function userAuthenticatedEvent({
    authenticationType,
    customerId,
  }: UserAuthenticatedProps) {
    const eventName = 'userAuthenticated';

    const payload = {
      correlationId: getRedlineCorrelationId(),
      authenticationType: authenticationType,
      customerId: customerId,
    };

    trackRedline.userAuthentication.userAuthenticated(payload);
    trackGA({ eventName, payload });
  }

  function userIdentifiedEvent(document: string) {
    const eventName = 'userIdentified';
    const payload = { document };
    trackRedline.userAuthentication.userIdentified(payload);
    trackGA({ eventName, payload });
  }

  function userExperimentEvent(
    experimentKey: string,
    variationValue: string,
    isControl: boolean
  ) {
    const payload = {
      experimentKey: experimentKey,
      variationValue: variationValue,
      isControl: isControl,
    };

    trackRedline.userTracking.experimentStarted(payload);
    trackGA({ eventName: 'experimentStarted', payload });
  }

  function userAttributeUpdatedEvent(eventPayload: userAttributeUpdatedProps) {
    const eventName = 'userAttributeUpdated';
    const payload = {
      ...eventPayload,
    };

    trackRedline.userAuthentication.userAttributeUpdated(payload);
    trackGA({ eventName, payload });
  }

  function debtLocated(partnerSystem: PartnerSystems) {
    trackRedline.debts.debtLocated({ partnerSystem });
  }

  function debtSimulated(
    payment: PaymentOptions,
    debt: Debt,
    partner: PartnerSystems
  ) {
    const payload = { paymentOption: payment, debt: { ...debt, partner } };
    trackRedline.debts.debtSimulated(payload, trackGA);
  }

  function debtViewed(debt: DebtResume, index: number) {
    const payload = debtEventsPayload(debt, index);
    trackRedline.debts.debtViewed(payload);
  }

  function debtClicked(debt: DebtResume, index: number) {
    const payload = debtEventsPayload(debt, index);
    trackRedline.debts.debtClicked(payload);
  }

  function debtEventsPayload(debt: DebtResume, index?: number) {
    const storageDebts = getFromStorage<SortingDebtsCache>(
      StorageMap.SortingDebts
    );

    const partner = storageDebts?.completeDebts.find(
      (x) => x.id === debt.partnerId
    );
    const fullDebt = partner?.debts?.find((x) => x.id === debt.id);
    const firstPaymentOption = fullDebt?.paymentOptions[0];

    return {
      position: index || 0,
      debt: { ...fullDebt, partner },
      paymentOption: firstPaymentOption,
    };
  }

  function sessionRecordedEvent(tool: string, recordingId: string) {
    const eventName = 'sessionRecorded';
    const payload = {
      tool: tool,
      recordingId: recordingId,
    };
    trackRedline.experience.sessionRecorded(payload);
    trackGA({ eventName, payload });
  }

  async function proposalStartedEvent({
    initialSlug,
    finalSlug,
    category,
    redirectUrl,
    fireboltId,
    redirectDebtsData,
  }: proposalStartedProps) {
    if (!fireboltId) return;

    const startSourceUrl = getCookie('start_source_url');

    const payload = {
      fireboltId,
      initialSlug,
      finalSlug,
      category,
      redirectUrl,
      startSourceUrl,
      rdg: getRDG(user?.document || ''),
      marketplace: 'cp',
      experience: 'logged',
      redirectDebtsData,
    };

    trackGA({ eventName: 'proposalStarted', payload });
    return trackRedline.experience.proposalStarted(payload);
  }

  function denakopAdRendered({ id, page }: DenakopAdRenderedPayload) {
    const eventName = 'denakop-ad-rendered';
    const payload = {
      page,
      id,
    };

    trackGA({ eventName, payload });
  }

  function appInfoTracked({
    deviceInfo,
    storeAppVersion,
  }: AppInfoTrackedPayload) {
    const eventName = 'appInfoTracked';
    const payload = {
      deviceInfo,
      storeAppVersion,
    };

    trackGA({ eventName, payload });
    return trackRedline.mobile.appInfoTracked(payload);
  }

  async function appSessionStarted(mobileSessionId: string) {
    const eventName = 'appSessionStarted';

    // Check if already started
    if (getCookie('rl-ass', true)) return;

    trackGA({ eventName });
    await trackRedline.mobile.appSessionStarted({
      mobileSessionId,
    });
    const webSessionId = await getSessionIDAsync();

    // Expire in 30min
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30);
    const cookieData = {
      key: 'rl_ass',
      value: { mobileSessionId, webSessionId },
      encrypt: true,
      expires: date,
    };

    return addCookie(cookieData);
  }

  return {
    fieldEvent,
    pageViewEvent,
    formStartedEvent,
    formContinuedEvent,
    formSubmittedEvent,
    elementClickedEvent,
    modalViewedEvent,
    productViewedEvent,
    productClickedEvent,
    redirectRequestedEvent,
    sessionRecordedEvent,
    shDecisionEnrichedEvent,
    shDecisionRequestedEvent,
    shDecisionOutcomeReceivedEvent,
    formOutcomeReceived,
    elementViewedEvent,
    checkRegisterStatusEvent,
    signUpStartedEvent,
    signUpCompletedEvent,
    signUpErroredEvent,
    signInStartedEvent,
    signInCompletedEvent,
    signInErroredEvent,
    userAuthenticatedEvent,
    userExperimentEvent,
    formErroredEvent,
    userIdentifiedEvent,
    proposalStartedEvent,
    debtLocated,
    debtSimulated,
    debtViewed,
    debtClicked,
    denakopAdRendered,
    appInfoTracked,
    appSessionStarted,
    userAttributeUpdatedEvent,
  };
};
