import classNames from 'classnames';
import { Conditional, Text } from '@consumidor-positivo/aurora';
import { useNavigate } from 'react-router-dom';
import OutlineArrowLeft from 'iq-blueberry/dist/reactIcons/OutlineArrowLeft';

import { Container } from '@common/components/Container';
import './styles.scss';
interface INavigationHeaderProps {
  onBack?: () => void;
  hideBackButton?: boolean;
  signInBackPosition?: boolean;
  text?: string;
}

export const LoggedOutHeader: React.FC<INavigationHeaderProps> = ({
  onBack,
  hideBackButton,
  signInBackPosition,
  text,
}) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('..', { relative: 'path', replace: true });
    }
  };

  const navigationClass = classNames('navigation-header__navigation', {
    'navigation-header__navigation--sign-in-width': signInBackPosition,
  });

  return (
    <div className="navigation-header">
      <Conditional
        condition={!signInBackPosition}
        renderIf={
          <Container>
            <InnerNav
              navigationClass={navigationClass}
              handleBack={handleBack}
              hideBackButton={hideBackButton}
              text={text}
            />
          </Container>
        }
        renderElse={
          <InnerNav
            navigationClass={navigationClass}
            handleBack={handleBack}
            hideBackButton={hideBackButton}
            text={text}
          />
        }
      />
    </div>
  );
};

type InnerNavProps = {
  navigationClass: string;
  hideBackButton?: boolean;
  handleBack?: () => void;
  text?: string;
};
const InnerNav: React.FC<InnerNavProps> = ({
  navigationClass,
  hideBackButton,
  handleBack,
  text,
}) => {
  return (
    <nav className={navigationClass}>
      <div className="navigation-header__nav-left">
        {!hideBackButton && (
          <div className="navigation-header__back-button">
            <div onClick={handleBack}>
              <OutlineArrowLeft />
            </div>
          </div>
        )}
      </div>
      <div className="navigation-header__text">
        <Text as="span" variant="body-medium">
          <strong>{text}</strong>
        </Text>
      </div>
    </nav>
  );
};
