export const waitForAFSmartScript = (): Promise<boolean> =>
  new Promise((resolve) => {
    const RETRY_INTERVAL = 500; // Retry every 500ms
    let ELAPSED = 0;

    const timer = setInterval(() => {
      if (window.AF_SMART_SCRIPT) {
        clearInterval(timer);
        resolve(true);
      } else if ((ELAPSED += RETRY_INTERVAL) >= 5000) {
        clearInterval(timer);
        resolve(false);
      }
    }, RETRY_INTERVAL);
  });
