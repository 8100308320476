import { getCookie } from "@libs/utils/helpers/getSafeCookies";

export function useRenegAC() {
    const isLoggedAc = getCookie('TOKEN')
    const enableRenegAC = isLoggedAc;

  return {
    enableRenegAC
  }
}
