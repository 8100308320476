import { useEffect, useState, useMemo } from 'react';
import * as QRCode from 'qrcode';

import { waitForAFSmartScript } from './helpers';
import {
  AfAttributionParameters,
  AfCustomParameters,
  AfParameters,
  GenerateURLParams,
  SmartScriptConfig,
} from './types';

const DEFAULT_CONFIG: SmartScriptConfig = {
  oneLinkURL: 'https://consumidorpositivo.onelink.me/3ote',
};

// Store the promise of AF_SMART_SCRIPT loading globally
let afSmartScriptPromise: Promise<boolean> | null = null;

/**
 * Custom hook for generating AppsFlyer Smart Script URLs and QR codes.
 * Provides functionality to create deep links and QR codes with attribution parameters.
 *
 * @param {Partial<SmartScriptConfig>} config - Configuration options for the smart script
 * @param {string} config.oneLinkURL - The AppsFlyer OneLink URL (defaults to 'https://consumidorpositivo.onelink.me/3ote')
 * @returns {{ generateOneLinkURL: Function, generateOneLinkQRCode: Function }} Object containing URL and QR code generation functions
 */
export function useSmartScript(config: Partial<SmartScriptConfig> = {}) {
  const { oneLinkURL } = { ...DEFAULT_CONFIG, ...config };
  const [isSmartScriptAvailable, setIsSmartScriptAvailable] = useState<
    boolean | null
  >(null);

  const afCustomParameters = useMemo<AfCustomParameters>(
    () => ({
      utm_term: { paramKey: 'utm_term', keys: ['utm_term'] },
      utm_content: { paramKey: 'utm_content', keys: ['utm_content'] },
      utm_keyword: { paramKey: 'utm_keyword', keys: ['utm_keyword'] },
      utm_product: { paramKey: 'utm_product', keys: ['utm_product'] },
      utm_campaign_type: {
        paramKey: 'utm_campaign_type',
        keys: ['utm_campaign_type'],
      },
      identify: { paramKey: 'identify', keys: ['identify'] },
      utm_medium: { paramKey: 'utm_medium', keys: ['utm_medium'] },
      utm_campaign: { paramKey: 'utm_campaign', keys: ['utm_campaign'] },
      utm_source: { paramKey: 'utm_source', keys: ['utm_source'] },
      is_retargeting: { paramKey: 'is_retargeting', defaultValue: 'true' },
      session_id: { paramKey: 'session_id', keys: ['session_id'] },
      custom_ss_ui: { paramKey: 'af_ss_ui', defaultValue: 'true' },
      af_base_params_forward: {
        paramKey: 'af_base_params_forward',
        defaultValue: 'false',
      },
    }),
    []
  );

  const afParameters = useMemo<AfParameters>(
    () => ({
      mediaSource: { keys: ['utm_source'], defaultValue: ['organico'] },
      campaign: { keys: ['utm_campaign'] },
      channel: { keys: ['utm_medium'] },
      googleClickIdKey: 'af_sub1',
      afSub2: { keys: ['fbclid'] },
      afCustom: Object.values(afCustomParameters),
    }),
    [afCustomParameters]
  );

  const ensureAfSmartScript = async (): Promise<boolean> => {
    // If we already know the availability status, return it immediately
    if (isSmartScriptAvailable !== null) {
      return isSmartScriptAvailable;
    }

    // Initialize the promise only once
    if (!afSmartScriptPromise) {
      afSmartScriptPromise = waitForAFSmartScript();
    }

    // Wait for the script to load and update state
    const result = await afSmartScriptPromise;
    setIsSmartScriptAvailable(result);
    return result;
  };

  const createInjectedParameters = (
    baseParams: AfParameters,
    extraParams: GenerateURLParams[]
  ): AfParameters => {
    const mergedParams = extraParams.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {} as GenerateURLParams
    );

    const customParamsFromArgs = Object.entries(mergedParams).map(
      ([key, value]) => ({
        paramKey: key,
        defaultValue: value,
      })
    );

    return {
      ...baseParams,
      afSub3: mergedParams.af_sub3
        ? { keys: ['af_sub3'], defaultValue: mergedParams.af_sub3 }
        : undefined,
      afSub4: mergedParams.af_sub4
        ? { keys: ['af_sub4'], defaultValue: mergedParams.af_sub4 }
        : undefined,
      afSub5: mergedParams.af_sub5
        ? { keys: ['af_sub5'], defaultValue: mergedParams.af_sub5 }
        : undefined,
      deepLinkValue: mergedParams.deep_link_value
        ? {
            keys: ['deep_link_value'],
            defaultValue: `${mergedParams.deep_link_value}`,
          }
        : undefined,
      afCustom: [...Object.values(afCustomParameters), ...customParamsFromArgs],
    };
  };

  useEffect(() => {
    // Initialize the SmartScript on component mount
    ensureAfSmartScript();
  }, []);

  /**
   * Generates a OneLink URL with injected attribution parameters.
   *
   * @param {string} fallbackURL - URL to return if OneLink generation fails
   * @param {...Array<GenerateURLParams>} params - Attribution parameter objects to include in the URL
   * @returns {Promise<string>} The generated OneLink URL or fallback URL if generation fails
   * @throws Will return fallback URL if AF_SMART_SCRIPT is not available or URL generation fails
   */
  const generateOneLinkURL = async (
    fallbackURL: string,
    ...params: Array<{ [K in keyof AfAttributionParameters]: string }>
  ): Promise<string> => {
    try {
      const available = await ensureAfSmartScript();

      if (!available) return fallbackURL;

      // Inject the extra custom params into afParameters
      const injectedParams = createInjectedParameters(afParameters, params);

      const { clickURL } = window.AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL,
        webReferrer: 'referrer_url',
        afParameters: injectedParams,
      });

      if (!clickURL) {
        console.warn('Failed to generate OneLink URL, using fallback');
        return fallbackURL;
      }

      return clickURL;
    } catch (error) {
      console.error('Error generating OneLink URL:', error);
      return fallbackURL;
    }
  };

  /**
   * Generates a QR code image containing a OneLink URL with attribution parameters.
   *
   * @param {string} fallbackURL - URL to use if OneLink generation fails
   * @param {...GenerateURLParams[]} params - Attribution parameter objects to include in the URL
   * @returns {Promise<string>} Base64 encoded PNG image of the QR code, or empty string if generation fails
   * @throws Will return empty string if QR code generation fails
   */
  const generateOneLinkQRCode = async (
    fallbackURL: string,
    ...params: GenerateURLParams[]
  ): Promise<string> => {
    try {
      const clickURL = await generateOneLinkURL(fallbackURL, ...params);

      const canvas = await QRCode.toCanvas(clickURL, {
        margin: 0,
        width: 256,
        errorCorrectionLevel: 'H',
      });
      return canvas.toDataURL('image/png');
    } catch (error) {
      console.error('Error generating QR code:', error);
      return '';
    }
  };

  return { generateOneLinkURL, generateOneLinkQRCode };
}
