import {
  IconVelocimeter,
  IconMoney,
  IconSearch,
  IconCreditCard,
  IconAlertCircle,
  IconStar,
  IconMessageCircle,
  IconBellOff,
} from '@consumidor-positivo/aurora';

export const notifyIcons = {
  score: <IconVelocimeter />,
  debts: <IconMoney />,
  lookout: <IconSearch />,
  offers: <IconCreditCard />,
  'negative-debts': <IconAlertCircle />,
  objective: <IconStar />,
  generic: <IconMessageCircle />,
  empty: <IconBellOff />,
};
