export enum StorageMap {
  Token = 'cp_tk',
  ScoreRecentVisit = 'cp_rv',
  User = 'cp_us',
  Score = 'cp_sc',
  Objectives = 'cp_ob',
  Historic = 'cp_ht',
  SortingDebts = 'cp_sd',
  UrlParams = 'cp_up',
  DashTab = 'cp_dt',
  UserExperience = 'cp_ue',
  MatchMaker = 'cp_mm',
  NegativeDebts = 'cp_nd',
  CpfLookout = 'cp_cl',
  PositiveData = 'cp_pd',
  FavoriteOffers = 'cp_fo',
  FavoriteModalDisplayed = 'cp_fm',
  CpfReport = 'cp_cr',
  SatisfactionSurvey = 'cp_ss',
  CountdownTimerReneg = 'cp_cdr',
  OverdraftData = 'cp_ov'
}
