import { useEffect, useState } from 'react';
import { Button } from 'iq-blueberry';
import { Text } from '@consumidor-positivo/aurora';
import { useRedline } from '@libs/redline';
import { delay } from '@libs/utils/helpers/delay';
import { useTracking } from '@common/hooks/useTracking';

import { Container } from '@common/components/Container';

import cpLogo from '@common/assets/logo-cp.svg';
import infoBox from '@common/assets/icon/info-box.svg';
import globalError from '@common/assets/illustration/global-error.webp';
import './styles.scss';

interface GlobalErrorProps {
  error: Error;
}

export const GlobalError: React.FC<GlobalErrorProps> = ({ error }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { elementClickedEvent } = useTracking();
  const { track } = useRedline();

  useEffect(() => {
    track.experience.appCrashed({
      error: String(error),
      description: 'CP App white screen',
    });
  }, []);

  const handleReload = async () => {
    elementClickedEvent({
      elementType: 'button/text',
      location: window.location.pathname,
      text: 'Tentar novamente',
      name: 'Error Boundary - Recarregar página',
    });

    setIsLoading(true);
    await delay(1000);
    window.location.href = `/?t=${Date.now()}`;
  };

  return (
    <main className="global-error">
      <header className="global-error__header">
        <Container customClass="global-error__navbar">
          <img
            className="global-error__logo"
            src={cpLogo}
            alt="Logo Consumidor Positivo"
          />
        </Container>
      </header>
      <Container customClass="global-error__content">
        <div className="global-error__left">
          <img
            className="global-error__icon"
            src={infoBox}
            alt="Tringulo com interrogação"
          />
          <Text as="h1" variantDesk="heading-big" variant="heading-medium">
            Ops! Parece que algo deu errado
          </Text>
          <Text
            as="p"
            variantDesk="body-large"
            variant="body-medium"
            color="secondary"
          >
            Não foi possível carregar a página, mas não se preocupe. Estamos
            trabalhando nisso, por favor, tente novamente.
          </Text>
          <Button
            size="medium"
            type="primary"
            color="default"
            expand="x"
            justify="center"
            disabled={false}
            loading={isLoading}
            onClick={handleReload}
          >
            Tentar novamente
          </Button>
        </div>
        <div className="global-error__right">
          <img
            src={globalError}
            alt="Mulher sentada utilizando um notebook no colo"
          />
        </div>
      </Container>
    </main>
  );
};
