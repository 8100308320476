import { Suspense, lazy, useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { delay } from '@libs/utils/helpers/delay';

import { LoaderCircular } from '@common/components/LoaderCircular';
import { RouteMap } from '@common/constants/RouteMap';
import { useTracking } from '@common/hooks/useTracking';
import { useUserContext } from '@common/contexts/UserContext';
import useDatadogRum from '@libs/utils/hooks/useDatadogRum';

import PublicRoutes from '@common/routes/PublicRoutes';
import PrivateRoutes from '@common/routes/PrivateRoutes';

import { Redirect } from '@common/components/Redirect';

import { useAdmissionRouter } from '@admission/routes/Router';
import { useBillsRouter } from '../../bills/routes/Router';
import { Router as useTallyRouter } from '@tally/routes/Router';
import { Router as useHubRouter } from '@hub/routers/Router';
import { Router as useDuesRouter } from '@dues/routes/Router';
import ErrorBoundary from '@common/components/ErrorBoundary';
import { GlobalError } from '@common/pages/GlobalError';
import { maskCpf } from '@libs/utils/masks/cpf';
import useApiLoginEnrichment from '@common/apis/useAPILoginEnrichment';

import constantsLegacyAuth from '@common/constants/legacyAuth';

import { addCookie } from '@libs/utils/helpers/getSafeCookies';
import { useDenakop } from '@common/components/Denakop/hooks';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const MonitoringCenter = lazy(() => import('../pages/MonitoringCenter'));
const HelpCenter = lazy(() => import('../pages/HelpCenter'));
const NotFound = lazy(() => import('../pages/NotFound'));

type LocationStateProps = {
  document: string;
  authOrigin: 'sign-in' | 'sign-up' | 'pin' | 'email-sign-in';
};

type RedirectAfterAuthByUtmTermProps = {
  [key: string]: string;
};

const redirectAfterAuthByUtmTerm: RedirectAfterAuthByUtmTermProps = {
  testelogado: '/ofertas/cards-itau-latampass-black',
};

const Router: React.FC = () => {
  const { apiLoginEnrichment } = useApiLoginEnrichment();
  const { admissionPrivatePages, admissionPublicPages } = useAdmissionRouter();
  const { isAuthenticated, user, usedAuthenticationType } = useUserContext();
  const pagesDues = useDuesRouter();
  const pagesTally = useTallyRouter();
  const pagesHub = useHubRouter();
  const pagesBills = useBillsRouter();

  const location = useLocation();

  const { pageViewEvent, userAuthenticatedEvent, signInErroredEvent } =
    useTracking();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sendPageViewDenakopGA } = useDenakop();

  const utmTerm = searchParams.get('utm_term');
  if (utmTerm) {
    sessionStorage.setItem('utm_term', utmTerm);
  }

  useEffect(() => {
    window?.scrollTo(0, 0);
    delay(150).then(() => pageViewEvent());

    sendPageViewDenakopGA();
  }, [location]);

  useEffect(() => {
    const utmTerm = sessionStorage.getItem('utm_term');
    const redirectUrl = utmTerm ? redirectAfterAuthByUtmTerm[utmTerm] : null;

    if (isAuthenticated && user) {
      const { document, authOrigin } =
        (location.state as LocationStateProps) || {
          document: maskCpf(user.document),
          authOrigin: usedAuthenticationType,
        };

      window.history.replaceState({}, '');
      apiLoginEnrichment.send();

      userAuthenticatedEvent({
        authenticationType: authOrigin,
        customerId: document,
      });

      const expireDays = new Date();
      expireDays.setDate(expireDays.getDate() + 120);

      addCookie({
        key: constantsLegacyAuth.cached_sign_in,
        value: user,
        expires: expireDays,
        encrypt: true,
      });

      if (redirectUrl) {
        navigate(redirectUrl);
        sessionStorage.removeItem('utm_term');
      }
    }
  }, [isAuthenticated, user]);

  useDatadogRum({
    applicationId: import.meta.env.VITE_DD_RUM_APP_ID,
    clientToken: import.meta.env.VITE_DD_RUM_TOKEN,
    version: import.meta.env.VITE_DEPLOY_VERSION,
    service: 'app.consumidorpositivo.com.br',
    sessionSampleRate: 1,
    sessionReplaySampleRate: 1,
  });

  return (
    <ErrorBoundary fallback={(error) => <GlobalError error={error} />}>
      <Suspense fallback={<LoaderCircular fullPage />}>
        <Routes>
          <Route element={<PublicRoutes />}>
            {admissionPublicPages}
            <Route
              path={RouteMap.Help}
              element={
                <Redirect to="https://ajuda.consumidorpositivo.com.br" />
              }
            />
          </Route>
          <Route element={<PrivateRoutes />}>
            {admissionPrivatePages}
            {pagesTally}
            {pagesHub}
            {pagesBills}
            {pagesDues}
            <Route path={RouteMap.Dashboard} element={<Dashboard />} />
            <Route
              path={RouteMap.MonitoringCenter}
              element={<MonitoringCenter />}
            />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
