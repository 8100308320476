import { createPortal } from 'react-dom';
import { Button } from 'iq-blueberry';
import { Text } from '@consumidor-positivo/aurora';

import { useSmartScript } from '@libs/smartscript';
import { Modal } from '../Modal';

import VerticalAppScoreDevice from '@common/assets/vertical-app-score-device.webp';
import defaultQRCode from '@common/assets/cp-app-qr-code.webp';

import './styles.scss';
import { useEffect, useRef } from 'react';

type AppStoresModalProps = {
  showModal: boolean;
  onClose: () => void;
};

export const AppStoresModal: React.FC<AppStoresModalProps> = ({
  showModal,
  onClose,
}) => {
  const qrCodeRef = useRef<HTMLImageElement>(null);
  const { generateOneLinkQRCode } = useSmartScript();

  useEffect(() => {
    (async () => {
      if (!qrCodeRef.current) return;

      const base64 = await generateOneLinkQRCode(
        'https://consumidorpositivo.onelink.me/3ote/pgvkj6v3',
        {
          af_sub3: JSON.stringify({
            type: 'qrcode',
            path: window?.location?.pathname,
            location: 'modal/baixe-nosso-app',
          }),
        }
      );

      qrCodeRef.current.setAttribute('src', base64);
    })();
  }, []);

  return createPortal(
    <Modal
      showModal={showModal}
      onClose={onClose}
      mobile="center"
      desktop="center"
    >
      <div className="app-stores-modal">
        <div className="app-stores-modal__content">
          <div className="app-stores-modal__body">
            <div className="app-stores-modal__header">
              <Text as="h2" variant="heading-small" variantDesk="heading-large">
                Baixe nosso app
              </Text>
              <Text as="p" variant="body-medium" variantDesk="body-large">
                É só apontar seu leitor de QR Code ou câmera e baixar.
              </Text>
            </div>

            <div className="app-stores-modal__qr-code">
              <img ref={qrCodeRef} src={defaultQRCode} alt="QR Code" />
            </div>

            <Button
              size="large"
              type="primary"
              color="default"
              justify="center"
              loading={false}
              disabled={false}
              expand="x"
              onClick={onClose}
            >
              Voltar
            </Button>
          </div>
        </div>

        <div className="app-stores-modal__device">
          <img src={VerticalAppScoreDevice} alt="Celular com Score" />
        </div>
      </div>
    </Modal>,
    document.body
  );
};
