import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { RouteMap } from '@common/constants/RouteMap';

const MyObjectives = lazy(() => import('../pages/MyObjectives'));
const ObjectiveDetail = lazy(() => import('../pages/ObjectiveDetail'));
const MyProgress = lazy(() => import('../pages/MyProgress'));
const Historic = lazy(() => import('../pages/Historic'));
const CpfReport = lazy(() => import('../pages/CpfReport'));

const isNativeApp = window.isNativeApp;

export function Router() {
  return (
    <>
      <Route path={RouteMap.Objectives} element={<MyObjectives />} />
      <Route path={RouteMap.ObjectiveDetail} element={<ObjectiveDetail />} />
      <Route path={RouteMap.ObjectivesProgress} element={<MyProgress />} />
      <Route path={RouteMap.ObjectivesHistoric} element={<Historic />} />

      <Route
        path={RouteMap.CpfReport}
        element={
          !isNativeApp ? <CpfReport /> : <Navigate to={RouteMap.Dashboard} />
        }
      />
    </>
  );
}
