import { ApiSigninResponse } from '@admission/apis/auth/types';
import { StorageMap } from '@common/constants/StorageMap';
import { base64Encrypt } from '@libs/utils/helpers/base64';
import safestorage from '@libs/utils/helpers/getSafeStorage';
import { maskCpf } from '@libs/utils/masks/cpf';
import {
  getAccessData,
  isUserFromWebView,
  redirectInApp,
} from './webviewComunication';

export default async function redirectToACImplicitAccess(
  userDocument: string,
  queryParams?: string,
  redirect = true
) {
  const formattedUserDocument = maskCpf(userDocument);
  const parts = formattedUserDocument.split('.');
  parts[1] = '***';

  const partialUserDocument = parts.join('.');

  const accessToken = await getUserAccessToken();

  let redirectURL =
    'https://www.acordocerto.com.br/?utm_source=consumidorpositivo&utm_medium=pendencias';
  if (accessToken) {
    const acUrlDomain = import.meta.env.VITE_AC_URL;

    const acUrlData = {
      access_token: accessToken,
    };

    const queryParamsWithoutQuestionMark = queryParams?.replace('?', '');
    redirectURL = `${acUrlDomain}/login?a=${base64Encrypt(
      JSON.stringify(acUrlData)
    )}&${queryParamsWithoutQuestionMark}`;
  }

  if (redirect) {
    redirectToAC(redirectURL);
  } else return redirectURL;
}

export function redirectToAC(redirectURL: string) {
  if (isUserFromWebView()) {
    return redirectInApp(redirectURL);
  }

  return setTimeout(() => window.open(redirectURL, '_top'));
}

async function getUserAccessToken() {
  let tokenStorage = await safestorage.getItem<ApiSigninResponse>(
    StorageMap.Token
  );

  if (isUserFromWebView()) {
    const token = await getAccessData();

    if (token) tokenStorage = token;
  }

  return tokenStorage?.access_token;
}
